<template>
  <b-card no-body>
    <b-card-header v-if="message.created_by && message.created_by" class="email-detail-head">
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar size="48" :src="message.created_by && message.created_by.profile_photo_path" class="mr-75" />
        <div class="mail-items">
          <h5 class="mb-0">
            {{ message.created_by && message.created_by.name }}
          </h5>
          <b-dropdown variant="link" no-caret toggle-class="p-0" class="email-info-dropup">
            <template #button-content>
              <span class="font-small-3 text-muted mr-25">{{ message.created_by && message.created_by.email }}</span>
              <feather-icon icon="ChevronDownIcon" size="10" />
            </template>
            <table class="table table-sm table-borderless font-small-3">
              <tbody>
                <tr>
                  <td class="text-right text-muted align-top">From:</td>
                  <td>{{ message.created_by && message.created_by.email }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">To:</td>
                  <td>
                    {{
                      message.assigned_to
                        .map((receiver) => receiver.email)
                        .filter((email, index, array) => array.indexOf(email) === index)
                        .join(", ")
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">Date:</td>
                  <td>{{ formatDate(message.date) }}</td>
                </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ formatDate(message.date) }}</small>
        <!-- Mail Action DD -->
        <!-- <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="17"
              class="ml-50 text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="CornerUpLeftIcon" />
            <span class="align-middle ml-50">Reply</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="CornerUpRightIcon" />
            <span class="align-middle ml-50">Forward</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown> -->
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div class="mail-message" v-html="message.description" />
      <!-- eslint-enable -->
      <div>
        <div class="d-flex justify-content-end mt-3" v-if="$route.params.folder !== 'sent'">
          <b-button
            variant="primary"
            size="sm"
            :to="{
              name: 'performance/goal',
              params: {
                id: message.id,
                type: 'new_goals',
              },
            }"
            v-if="message.reminder_type && message.reminder_type.name == 'Goal Settings' && getRole() != 'director'"
          >
            <feather-icon icon="ArrowRightIcon" />
            Create Goal
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            :to="{
              name: 'performance/goal/review',
              params: {
                id: message.created_by && message.created_by.id,
                type: 'goals_setting',
                isMeeting: message.status == 'new' ? false : true,
              },
            }"
            v-else
          >
            <feather-icon icon="ArrowRightIcon" />
            View Goal
          </b-button>
          <b-link
            :to="{
              name: 'performance/goal',
              params: {
                id: message.id,
                type: 'new_midyear',
              },
            }"
            v-if="message.reminder_type && message.reminder_type.name == 'Mid Year Review'"
          >
            <b-button variant="primary" size="sm" v-if="getRole() != 'director'">
              <feather-icon icon="ArrowRightIcon" />
              Update Mid Year
            </b-button>
          </b-link>
          <b-link
            :to="{
              name: 'performance/goal',
              params: {
                id: message.id,
                type: 'new_final',
              },
            }"
            v-if="message.reminder_type && message.reminder_type.name == 'Final Evaluation'"
          >
            <b-button variant="primary" size="sm" v-if="getRole() != 'director'">
              <feather-icon icon="ArrowRightIcon" />
              Update Final Annual Evaluation
            </b-button>
          </b-link>
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="message.reminder_type && message.reminder_type.name == 'Setup Meeting'"
          >
            <a :href="message.location">
              <b-button variant="outline-primary" size="sm" class="mr-1">
                <feather-icon icon="CameraIcon" />
                Open Zoom
              </b-button>
            </a>
            <b-link
              :to="{
                name: 'performance/goal',
                params: {
                  id: message.id,
                  type: 'new_goals_meeting',
                },
              }"
            >
              <b-button variant="primary" size="sm">
                <feather-icon icon="ArrowRightIcon" />
                See Goal
              </b-button>
            </b-link>
          </div>
        </div>
      </div>
    </b-card-body>

    <!-- <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon icon="PaperclipIcon" size="16" />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            {{ message.attachments.length }} Attachment{{
              message.attachments.length > 1 ? "s" : ""
            }}
          </h5>
        </div>

        <div class="d-flex flex-column">
          <b-link
            v-for="(attachment, index) in message.attachments"
            :key="index"
            :href="attachment.url"
            target="_blank"
            :class="{ 'mt-75': index }"
          >
            <b-img :src="attachment.thumbnail" width="16px" class="mr-50" />
            <span class="text-muted font-weight-bolder align-text-top">{{
              attachment.fileName
            }}</span>
            <span class="text-muted font-small-2 ml-25"
              >({{ attachment.size }})</span
            >
          </b-link>
        </div>
      </div>
    </b-card-footer> -->
  </b-card>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BAvatar,
  BLink,
  BImg,
} from "bootstrap-vue";
import { formatDate } from "@core/utils/filter";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    };
  },
};
</script>

<style>
</style>
