var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-app-details"},[_c('div',{staticClass:"email-detail-header"},[_c('div',{staticClass:"email-header-left d-flex align-items-center"},[_c('span',{staticClass:"go-back mr-1"},[_c('feather-icon',{staticClass:"align-bottom",attrs:{"icon":_vm.$store.state.appConfig.isRTL
              ? 'ChevronRightIcon'
              : 'ChevronLeftIcon',"size":"20"},on:{"click":function($event){return _vm.$emit('close-email-view')}}})],1),_c('h4',{staticClass:"email-subject mb-0"},[_vm._v(" "+_vm._s(_vm.emailViewData.subject)+" ")])]),_c('div',{staticClass:"email-header-right ml-2 pl-1"},[_c('feather-icon',{staticClass:"ml-75 cursor-pointer",class:{
          'text-muted pointer-events-none':
            !_vm.opendedEmailMeta.hasPreviousEmail,
        },attrs:{"icon":_vm.$store.state.appConfig.isRTL
            ? 'ChevronRightIcon'
            : 'ChevronLeftIcon',"size":"17"},on:{"click":function($event){return _vm.$emit('change-opened-email', 'previous')}}}),_c('feather-icon',{staticClass:"ml-75 cursor-pointer",class:{
          'text-muted pointer-events-none': !_vm.opendedEmailMeta.hasNextEmail,
        },attrs:{"icon":_vm.$store.state.appConfig.isRTL
            ? 'ChevronLeftIcon'
            : 'ChevronRightIcon',"size":"17"},on:{"click":function($event){return _vm.$emit('change-opened-email', 'next')}}})],1)]),_c('vue-perfect-scrollbar',{staticClass:"email-scroll-area scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"email-label"},_vm._l((_vm.emailViewData.labels),function(label){return _c('b-badge',{key:label,staticClass:"text-capitalize mr-50",attrs:{"pill":"","variant":("light-" + (_vm.resolveLabelColor(label)))}},[_vm._v(" "+_vm._s(label)+" ")])}),1)])],1),(
        !_vm.showWholeThread &&
        _vm.emailViewData.replies &&
        _vm.emailViewData.replies.length
      )?_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-link',{staticClass:"font-weight-bold",on:{"click":function($event){_vm.showWholeThread = true}}},[_vm._v(" View "+_vm._s(_vm.emailViewData.replies.length)+" Earlier Message"+_vm._s(_vm.emailViewData.replies.length > 1 ? "s" : "")+" ")])],1)],1):_vm._e(),(_vm.showWholeThread)?_vm._l((_vm.emailViewData.replies.slice().reverse()),function(threadMail){return _c('b-row',{key:threadMail.id},[_c('b-col',{attrs:{"cols":"12"}},[_c('email-message-card',{attrs:{"message":threadMail}})],1)],1)}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('email-message-card',{attrs:{"message":_vm.emailViewData}})],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }