var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"compose-mail","visible":_vm.shallShowEmailComposeModal,"title":"Compose Email","modal-class":"modal-sticky","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"lg","no-fade":"","hide-backdrop":"","static":""},on:{"change":function (val) { return _vm.$emit('update:shall-show-email-compose-modal', val); }},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v("New Inbox")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MinusIcon"},on:{"click":function($event){return _vm.$emit('update:shall-show-email-compose-modal', false)}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"Maximize2Icon"}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":_vm.discardEmail}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon","size":"17"},on:{"click":_vm.discardEmail}})],1),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"split":"","variant":"primary","right":""},on:{"click":_vm.sendEmail}},[_vm._v(" Send Inbox ")])],1)]},proxy:true}])},[_c('b-form',[_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v("Type: ")]),_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","close-on-select":false,"options":_vm.emailTypeOptions,"input-id":"email-type"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.composeData.type),callback:function ($$v) {_vm.$set(_vm.composeData, "type", $$v)},expression:"composeData.type"}})],1),_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v("To: ")]),_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-to"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.composeData.assigned_to),callback:function ($$v) {_vm.$set(_vm.composeData, "assigned_to", $$v)},expression:"composeData.assigned_to"}})],1),_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"email-subject"}},[_vm._v("Subject: ")]),_c('b-form-input',{attrs:{"id":"email-subject"},model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"email-start-date"}},[_vm._v("Start: ")]),_c('flat-pickr',{staticClass:"form-control bg-white",attrs:{"config":{ dateFormat: 'd F Y' }},model:{value:(_vm.composeData.start_period),callback:function ($$v) {_vm.$set(_vm.composeData, "start_period", $$v)},expression:"composeData.start_period"}})],1)]),_c('b-col',[_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"email-end-date"}},[_vm._v("End: ")]),_c('flat-pickr',{staticClass:"form-control bg-white",attrs:{"config":{ dateFormat: 'd F Y' }},model:{value:(_vm.composeData.end_period),callback:function ($$v) {_vm.$set(_vm.composeData, "end_period", $$v)},expression:"composeData.end_period"}})],1)])],1),_c('div',{staticClass:"message-editor"},[_c('quill-editor',{attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.composeData.description),callback:function ($$v) {_vm.$set(_vm.composeData, "description", $$v)},expression:"composeData.description"}}),_c('div',{staticClass:"d-flex border-bottom-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }