<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Compose Email"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">New Inbox</h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="$emit('update:shall-show-email-compose-modal', false)"
        />
        <feather-icon icon="Maximize2Icon" class="ml-1 cursor-pointer" />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <!-- Discard Compose -->
        <feather-icon
          icon="TrashIcon"
          size="17"
          class="cursor-pointer"
          @click="discardEmail"
        />
      </div>

      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          split
          variant="primary"
          right
          @click="sendEmail"
        >
          Send Inbox
        </b-button>
      </div>
    </template>

    <!-- Modal: Body -->
    <b-form>
      <div class="compose-mail-form-field">
        <label for="email-to" class="form-label">Type: </label>
        <v-select
          v-model="composeData.type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          class="flex-grow-1 email-to-selector"
          :close-on-select="false"
          :options="emailTypeOptions"
          input-id="email-type"
        >
          <template #selected-option="{ name }">
            <span class="ml-50"> {{ name }}</span>
          </template>
        </v-select>
      </div>
      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label for="email-to" class="form-label">To: </label>
        <v-select
          v-model="composeData.assigned_to"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          class="flex-grow-1 email-to-selector"
          :close-on-select="false"
          :options="emailToOptions"
          input-id="email-to"
        >
          <template #option="{ avatar, name }">
            <b-avatar size="sm" :src="avatar" />
            <span class="ml-50"> {{ name }}</span>
          </template>

          <template #selected-option="{ avatar, name }">
            <b-avatar size="sm" class="border border-white" :src="avatar" />
            <span class="ml-50"> {{ name }}</span>
          </template>
        </v-select>
      </div>

      <!-- Field: Subject -->
      <div class="compose-mail-form-field">
        <label for="email-subject">Subject: </label>
        <b-form-input id="email-subject" v-model="composeData.subject" />
      </div>
      <b-row>
        <b-col md="6">
          <div class="compose-mail-form-field">
            <label for="email-start-date">Start: </label>
            <flat-pickr
              v-model="composeData.start_period"
              class="form-control bg-white"
              :config="{ dateFormat: 'd F Y' }"
            />
          </div>
        </b-col>
        <b-col>
          <div class="compose-mail-form-field">
            <label for="email-end-date">End: </label>
            <flat-pickr
              v-model="composeData.end_period"
              class="form-control bg-white"
              :config="{ dateFormat: 'd F Y' }"
            />
          </div>
        </b-col>
      </b-row>

      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <quill-editor
          id="quil-content"
          v-model="composeData.description"
          :options="editorOption"
        />
        <div id="quill-toolbar" class="d-flex border-bottom-0">
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BAvatar,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import moment from "moment";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,

    // 3rd Party
    quillEditor,
    vSelect,
  },
  model: {
    prop: "shallShowEmailComposeModal",
    event: "update:shall-show-email-compose-modal",
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      emailToOptions: [],
      emailTypeOptions: [],
      datas: [],
    };
  },
  mounted() {
    this.callApi({
      url: "reminder-types/fetch",
      method: "GET",
      success: (res) => {
        res.result.map((item) => {
          this.emailTypeOptions.push({
            id: item.id,
            name: item.name,
          });
        });
      },
    });

    this.callApi({
      url: "/users/getAll",
      method: "GET",
      params: {
        limit: 100,
      },
      success: (res) => {
        res.result.data.map((item) => {
          this.emailToOptions.push({
            id: `${item.id}`,
            name: item.name,
            avatar: item.profile_photo_path,
          });
        });

        // exclude current user
        this.emailToOptions = this.emailToOptions.filter(
          (item) =>
            item.id != JSON.parse(localStorage.getItem("sw_auth_data")).id &&
            item.id != 1
        );

        // all employee
        const currentUserID = JSON.parse(
          localStorage.getItem("sw_auth_data")
        ).id;
        const ids = res.result.data
          .filter((item) => item.id !== currentUserID && item.id != 1)
          .map((item) => item.id)
          .join(",");
        this.emailToOptions.push({
          id: ids,
          name: "All",
        });
      },
    });
  },
  methods: {
    sendEmail() {
      const data = {
        reminder_type_id: this.composeData.type.id,
        subject: this.composeData.subject,
        created_by: JSON.parse(localStorage.getItem("sw_auth_data")).id,
        assigned_to:
          this.composeData.assigned_to[0].name == "All"
            ? this.composeData.assigned_to[0].id
            : this.composeData.assigned_to.map((item) => item.id).join(","),
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        start_period: moment(this.composeData.start_period).format(
          "YYYY-MM-DD"
        ),
        end_period: moment(this.composeData.end_period).format("YYYY-MM-DD"),
        description: this.composeData.description,
      };
      this.callApi({
        url: "reminders/create",
        method: "POST",
        data: data,
        success: (res) => {
          this.$bvModal.hide("compose-mail");
          this.composeData = {};
        },
      });
    },
  },
  setup(_, { emit }) {
    const composeData = ref({});
    const showCcField = ref(false);
    const showBccField = ref(false);

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Message",
    };

    const discardEmail = () => {
      composeData.value = {};
      emit("update:shall-show-email-compose-modal", false);
    };

    return {
      composeData,
      editorOption,
      showCcField,
      showBccField,

      // Email actions
      discardEmail,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
