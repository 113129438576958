import axios from "@axios";
import constant from "@/constant";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmails(ctx, payload) {
      return new Promise((resolve, reject) => {
        var token = "";
        var nameEQ = "sw_auth_token" + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0)
            token = c.substring(nameEQ.length, c.length);
        }

        axios({
          url: `${constant.BASE_API}/reminders/fetch`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: payload,
          timeout: 10000,
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/email/update-emails", payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEmailLabels(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/email/update-emails-label", payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    paginateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/email/paginate-email", { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
