<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="
                $emit('update:shall-show-email-compose-modal', true);
                $emit('close-left-sidebar');
              "
              :disabled="this.getRole() == 'staff'"
            >
              New Inbox
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in emailFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">{{
                  filter.title
                }}</span>
                <b-badge
                  v-if="emailsMeta[filter.title.toLowerCase()]"
                  pill
                  :variant="resolveFilterBadgeColor(filter.title)"
                  class="float-right"
                >
                  {{ emailsMeta[filter.title.toLowerCase()] }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <!-- <h6 class="section-label mt-3 mb-1 px-2">Labels</h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :to="label.route"
                :active="isDynamicRouteActive(label.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group> -->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailsMeta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emailFilters: [],
    };
  },
  mounted() {
    if (this.getRole() == "manager" || this.getRole() == "director") {
      this.emailFilters = [
        {
          title: "Inbox",
          icon: "MailIcon",
          route: { name: "apps-email" },
        },
        {
          title: "Sent",
          icon: "SendIcon",
          route: {
            name: "apps-email-folder",
            params: { folder: "sent" },
          },
        },
      ];
    } else {
      this.emailFilters = [
        {
          title: "Inbox",
          icon: "MailIcon",
          route: { name: "apps-email" },
        },
      ];
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const emailLabel = [
      // {
      //   title: "Personal",
      //   color: "success",
      //   route: { name: "apps-email-label", params: { label: "personal" } },
      // },
      // {
      //   title: "Company",
      //   color: "primary",
      //   route: { name: "apps-email-label", params: { label: "company" } },
      // },
      // {
      //   title: "Important",
      //   color: "warning",
      //   route: { name: "apps-email-label", params: { label: "important" } },
      // },
      // {
      //   title: "Private",
      //   color: "danger",
      //   route: { name: "apps-email-label", params: { label: "private" } },
      // },
    ];

    const resolveFilterBadgeColor = (filter) => {
      if (filter === "Draft") return "light-warning";
      if (filter === "Spam") return "light-danger";
      return "light-primary";
    };

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailLabel,
    };
  },
};
</script>

<style>
</style>
